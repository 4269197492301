<h4 class="title is-4">
  <app-element-temporal-datetime [dateTime]="datetime" format="longMonth"></app-element-temporal-datetime>
</h4>

<div class="controls-container">
  <a [routerLink]="'/calendar/' + previousCalendar.year + '/' + previousCalendar.month | localize" class="button">
    <fa-icon [icon]="iconLeft"></fa-icon>
  </a>
  <a [routerLink]="'/calendar' | localize" class="button">
    {{ 'calendar.now' | translate }}
  </a>
  <a [routerLink]="'/calendar/' + nextCalendar.year + '/' + nextCalendar.month | localize" class="button">
    <fa-icon [icon]="iconRight"></fa-icon>
  </a>
</div>


<!-- TODO: add month selector -->
<div class="controls-container">
  <nwb-switch [ngModel]="calendarView"
              (ngModelChange)="changeCalendarView($event)"
              name="CalendarView">Calender View</nwb-switch>
</div>
