[
  "N/A",
  "Other",
  "2DS",
  "2DS Backwards Compatibility",
  "2DS XL",
  "3DO Interactive Multiplayer",
  "3DS Backwards Compatibility",
  "Acorn Archimedes",
  "Advanced Pico Beena",
  "Airplane Seats",
  "Amazon Fire TV",
  "Amiga",
  "Amiga CD32",
  "Amstrad CPC",
  "Amstrad PCW",
  "Analogue Nt",
  "Analogue Nt Mini",
  "Analogue Super Nt",
  "Android",
  "AndroidTV",
  "Apple II",
  "Apple IIGS",
  "Apple TV",
  "Apple Watch",
  "Arcade",
  "Arduboy",
  "Atari 2600",
  "Atari 5200",
  "Atari 7800",
  "Atari 8-bit family (400/800/XL/XE)",
  "Atari Flashback",
  "Atari Jaguar",
  "Atari Jaguar CD",
  "Atari Lynx",
  "Atari ST",
  "Atari VCS",
  "backwards-compatible PlayStation 3",
  "BBC Micro",
  "Binary Runtime Environment for Wireless",
  "Casio Loopy",
  "Casio PV-2000",
  "Chromebook",
  "ColecoVision",
  "Commodore 128 Computer",
  "Commodore 64",
  "Commodore 64 Mini",
  "Commodore CDTV",
  "Commodore VIC-20",
  "Dragon 32/64",
  "Dreamcast",
  "DSi",
  "DVD Player",
  "Electronic Delay Storage Automatic Calculator",
  "Evercade",
  "Fairchild Channel F",
  "Famicom",
  "Famicom Disk System",
  "Flash",
  "FM Towns",
  "Game & Watch",
  "Game Boy",
  "Game Boy Advance",
  "Game Boy Color",
  "Game Boy Interface",
  "Game Boy Player",
  "Game.Com",
  "GameCube",
  "GamePark 32",
  "Gizmondo",
  "Google Stadia",
  "Graphing Calculator",
  "HTC Vive",
  "HTC Vive Pro",
  "Hyperscan",
  "Intellivision",
  "iOS",
  "iPod",
  "iQue Player",
  "Java Phone",
  "LCD handheld",
  "Leapfrog Didj",
  "Leapster Learning Game System",
  "Leapster TV",
  "Linux",
  "LSI",
  "Macintosh",
  "Magnavox Odyssey",
  "Magnavox Odyssey 2",
  "Mega Sg",
  "MegaNet",
  "MiSTer",
  "MS-DOS",
  "MSX",
  "MSX2",
  "N-Gage",
  "NEC PC-88 series",
  "NEC PC-98 series",
  "Neo Geo AES",
  "Neo Geo CD",
  "Neo Geo Mini",
  "Neo Geo Pocket Color",
  "Neo Geo X",
  "NES Classic Mini",
  "New Nintendo 3DS",
  "New Nintendo 3DS Virtual Console",
  "Nintendo 3DS",
  "Nintendo 3DS Virtual Console",
  "Nintendo 64",
  "Nintendo 64 Disk Drive",
  "Nintendo DS",
  "Nintendo Entertainment System",
  "Nintendo Switch Lite",
  "Nt Mini Noir",
  "Nuon",
  "Nvidia Shield",
  "Oculus Quest",
  "Oculus Quest 2",
  "Oculus Quest 3",
  "Oculus Rift",
  "Oculus Rift S",
  "Oculus VR",
  "Oric",
  "Ouya",
  "Palm OS",
  "PC",
  "PC-FX",
  "Philips CD-i",
  "PICO 4",
  "PICO-8",
  "Playdate",
  "PlayStation",
  "PlayStation 2",
  "PlayStation 3",
  "PlayStation 4",
  "PlayStation 4 Pro",
  "PlayStation 5",
  "PlayStation Classic",
  "PlayStation Now",
  "PlayStation Portable",
  "PlayStation TV",
  "PlayStation Vita",
  "Plug and Play",
  "Pocket Dream Console",
  "Pokémon Mini",
  "Pong Consoles",
  "PS5 for backward compatible games",
  "PSN Download",
  "retroUSB AVS",
  "Roku",
  "Satellaview",
  "Sega 32X",
  "Sega CD",
  "Sega Game Gear",
  "Sega Genesis",
  "Sega Genesis Mini",
  "Sega Master System",
  "Sega Pico",
  "Sega Saturn",
  "SG-1000",
  "Sharp X1",
  "SNES Classic Mini",
  "Steam Deck",
  "Super Cassette Vision",
  "Super Famicom",
  "Super Famicom Turbo",
  "Super Game Boy",
  "Super Game Boy 2",
  "Super Nintendo",
  "Switch",
  "Switch Virtual Console",
  "SwitchOLED",
  "Tabletop",
  "Tamagotchi",
  "Tapwave Zodiac",
  "Temple Operating System",
  "Texas Instruments TI-99/4A",
  "TI-84",
  "TIC-80",
  "Tiger",
  "TurboGrafx-16 CD-ROM",
  "TurboGrafx-16 Mini",
  "TurboGrafx-16/PC Engine",
  "V.Smile",
  "Valve Index",
  "Vectrex",
  "Video Game Machine - Multiframe Colour - 1",
  "Virtual Boy",
  "Web",
  "Wii",
  "Wii Backwards compatibility",
  "Wii Mini",
  "Wii U",
  "Wii U Virtual Console",
  "Wii Virtual Console",
  "WiiU Backwards Compatibility",
  "Windows Mixed Reality",
  "Windows Phone",
  "WonderSwan",
  "Wonderswan Colour",
  "X68000",
  "Xbox",
  "Xbox 360",
  "Xbox 360 Arcade",
  "Xbox 360 Backwards Compatibility",
  "Xbox Game Pass",
  "Xbox One",
  "Xbox One S",
  "Xbox One X",
  "Xbox Series S",
  "Xbox Series X",
  "XTAL VR",
  "Zeebo",
  "ZX Spectrum"
]